import { Pipe, PipeTransform } from '@angular/core';
import { AppConfig } from '../app-config';
import * as moment from 'moment';


const DEFAULT_INPUT_FORMAT = 'YYYY-MM-DD',
  DEFAULT_PRECISION = 'month';

@Pipe({
  name: 'defaultDate'
})
export class DefaultDatePipe implements PipeTransform {
/*
  DEFAULT_DATE_OUTPUT_PRECISION: Object;

  constructor(injector: Injector) {
    const config = injector.get(APP_CONFIG);
    this.DEFAULT_DATE_OUTPUT_PRECISION = config.get('DEFAULT_DATE_OUTPUT_PRECISION');
    console.log('^^^', config);
  }
*/

  transform(value: any, precision = DEFAULT_PRECISION, autoDetectFormat: boolean = false, inLocalTime: boolean = true): any {
    let outputFormat;

    outputFormat = AppConfig.DEFAULT_DATE_OUTPUT_FORMATS[precision.toLowerCase()]
      || AppConfig.DEFAULT_DATE_OUTPUT_FORMATS[DEFAULT_PRECISION];

    if (!value) {
      return undefined;
    }

    let date = moment.utc(value, !autoDetectFormat ? DEFAULT_INPUT_FORMAT : undefined);

    if (!date.isValid()) {
      console.error(`[DefaultDatePipe] Date ${value} has invalid format`);
      return undefined;
    }

    if (!inLocalTime) {
      return date.format(outputFormat);
    }

    return date.local().format(outputFormat);
  }
}
