import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap } from 'rxjs';
// Configs
import { AppConfig } from '../app-config';
// Interfaces
import { ICvLanguage } from '../interfaces/cv-language';

interface LanguageDictResponse {
  Languages: Array<ICvLanguage>;
}

@Injectable()
export class CvLanguageService {
  constructor(private http: HttpClient, private appConfig: AppConfig) {}

  get(languageCode: string): Promise<ICvLanguage> {
    return this.appConfig
      .get('Languages')
      .then((languages: Array<ICvLanguage>) => {
        const lang = languages.find((el) => el.LanguageCode === languageCode);
        if (lang) {
          lang.FlagUrl =
            AppConfig.imagesPath + `/flags/${lang.LanguageCode}.svg`;
        }
        return lang;
      });
  }

  getAll(): Observable<Array<ICvLanguage>> {
    return this.http
      .get<LanguageDictResponse>(this.appConfig.CONFIG_API_URLS.languages)
      .pipe(
        switchMap((response) => {
          const languages = response.Languages;

          languages.map((lang) => {
            lang.FlagUrl = lang.FlagUrl =
              AppConfig.imagesPath + `/flags/${lang.LanguageCode}.svg`;
            return lang;
          });

          return of(languages);
        })
      );
  }
}
