// modules
import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { My7nMaterialModule } from './my7n-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from 'primeng/editor';
import { CalendarModule } from 'primeng/calendar';
import { NgSelectModule } from '@ng-select/ng-select';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { My7nUiModule } from '@my7n/ui';
import { My7nSharedModule } from './my7n-shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RxLet } from '@rx-angular/template/let';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { YouTubePlayerModule } from '@angular/youtube-player';

// pipes
import { DefaultDatePipe } from '../pipes/default-date.pipe';
import { CountToDatePipe } from '../pipes/count-to-date.pipe';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { RemoveByTagsPipe } from '../pipes/remove-by-tags.pipe';
import { HighlightPipe } from '../pipes/highlight.pipe';
import { TrustedUrlPipe } from '../pipes/trusted-url.pipe';
import { FilesizePipe } from '../pipes/filesize.pipe';
import { TrustedHtmlPipe } from '../pipes/trusted-html.pipe';

// components
import { CvListElementComponent } from '../components/cv/cv-list-view/cv-list/cv-list-element/cv-list-element.component';
import { CheckboxesCategoriesDetailsComponent } from '../components/common/checkboxes-categories-details/checkboxes-categories-details.component';
import { CheckboxCategoryListItemComponent } from '../components/common/checkbox-category-list-item/checkbox-category-list-item.component';
import { CheckboxCategoryComponent } from '../components/common/checkbox-category/checkbox-category.component';
import { CheckboxesCategoriesEditComponent } from '../components/common/checkboxes-categories-edit/checkboxes-categories-edit.component';
import { ImagePreloaderComponent } from '../components/common/image-preloader/image-preloader.component';
import { LabeledEditComponent } from '../components/common/labeled-edit/labeled-edit.component';
import { PhonesEditComponent } from '../components/common/phones-edit/phones-edit.component';
import { AddressEditComponent } from '../components/common/address-edit/address-edit.component';
import { LocationEditComponent } from '../components/common/location-edit/location-edit.component';
import { PrintSettingsComponent } from '../components/common/print-settings/print-settings.component';
import { PrintSettingsDialogComponent } from '../components/common/print-settings/print-settings-dialog/print-settings-dialog.component';
import { ImagePickerComponent } from '../components/common/image-picker/image-picker.component';
import { CustomUploadComponent } from '../components/common/image-picker/custom-upload/custom-upload.component';
import { PrintPreviewDialogComponent } from '../components/common/print-preview-dialog/print-preview-dialog.component';
import { PopoverComponent } from '../components/common/popover/popover.component';
import { SkillsSelectorComponent } from '../components/common/skills-selector/skills-selector.component';
import { SecureAvatarComponent } from '../components/common/secure-avatar/secure-avatar.component';
import { CvPrimaryDialogComponent } from '../components/cv/cv-list-view/cv-list/cv-list-element/cv-primary-dialog/cv-primary-dialog.component';
import { ConsultantCvPrimaryDialogComponent } from '../components/agent/agent-consultant-view/consultant-cv-list-element/consultant-cv-primary-dialog/consultant-cv-primary-dialog.component';
import { FilesUploadComponent } from '../components/common/files-upload/files-upload.component';
import { FilesUploadElementComponent } from '../components/common/files-upload/files-upload-element/files-upload-element.component';
import { FileUploadComponent } from '../components/common/file-upload/file-upload.component';
import { FileUploadElementComponent } from '../components/common/file-upload/file-upload-element/file-upload-element.component';
import { FileUploadPdfComponent } from '../components/common/file-upload-pdf/file-upload-pdf.component';
import { FileUploadPdfElementComponent } from '../components/common/file-upload-pdf/file-upload-pdf-element/file-upload-pdf-element.component';
import { TestimonialComponent } from '../components/common/testimonial/testimonial.component';
import { EventBoxComponent } from '../components/common/event-box/event-box.component';
import { EventBoxElementComponent } from '../components/common/event-box/event-box-element/event-box-element.component';
import { CvParseComponent } from '../components/common/cv-parse/cv-parse.component';
import { CvParseDialogComponent } from '../components/common/cv-parse/cv-parse-dialog/cv-parse-dialog.component';
import { VideoPlayerComponent } from '../components/common/video-player/video-player.component';
import { SharepointGalleryComponent } from '../components/common/sharepoint-gallery/sharepoint-gallery.component';
import {
  SharepointGalleryThumbComponent
} from '../components/common/sharepoint-gallery/sharepoint-gallery-thumb/sharepoint-gallery-thumb.component';
import { SystemMessagesComponent } from '../components/common/system-messages/system-messages.component';

// directives
import { AuthCanDirective } from '../directives/auth-can.directive';
import { AuthCanSomeDirective } from '../directives/auth-can-some.directive';
import { AuthCantSomeDirective } from '../directives/auth-cant-some.directive';
import { AuthCantDirective } from '../directives/auth-cant.directive';
import { IfModuleEnabledDirective } from '../directives/if-module-enabled.directive';
import { Nav2ndLevelHostDirective } from '../directives/nav-2nd-level-host.directive';
import { InputSanitizeControlCharactersDirective } from '../directives/input-sanitize-control-characters.directive';
import { ScrollToErrorDirective } from '../directives/scroll-to-error.directive';
import { GalleryPhotoDirective } from '../directives/gallery-photo.directive';
import { MatButtonLoadingDirective } from '../directives/mat-button-loading.directive';
import { IsElementVisibleDirective } from '../directives/is-element-visible.directive';

// services
import { PrintSettingsService } from '../services/print-settings.service';
import { MatMenuCustomService } from '../services/mat-menu-custom.service';
import { DownloadService } from '../services/download.service';
import { ImagePreloaderService } from '../services/image-preloader.service';
import { ScrollToElementService } from '../services/scroll-to-element.service';
import { SecureImageService } from '../services/secure-image.service';
import { FabAgentService } from '../services/fab-agent.service';
import { FeedbackService } from '../services/feedback.service';
import { MessageBusService } from '../services/message-bus.service';
import { PreviewService } from '../services/preview.service';
import { PrintService } from '../services/print.service';
import { QueryParamsFeatureEnablerService } from '../services/query-params-feature-enabler.service';
import { ReactionsService } from '../services/reactions.service';
import { DictionariesService } from '../services/dictionaries.service';
import { StorageCacheService } from '../services/storage-cache.service';
import { UserProfileService } from '../services/user-profile.service';

// facades
import { GalleryFacadeService } from '../services/facades/gallery-facade.service';
import { GlobalFacadeService } from '../services/facades/global-facade.service';
import { NotificationsFacade } from '../services/facades/notifications-facade.service';
import { DictionariesFacadeService } from '../services/facades/dictionaries-facade.service';

// store
import * as fromGlobalState from '../store/reducers/global';
import { GlobalEffects } from '../store/effects/global.effects';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    My7nMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    NgSelectModule,
    EditorModule,
    CalendarModule,
    My7nUiModule,
    My7nSharedModule,
    PdfJsViewerModule,
    ClipboardModule,
    StoreModule.forFeature(fromGlobalState.globalFeatureKey, fromGlobalState.reducers),
    EffectsModule.forFeature([GlobalEffects]),
    ImageCropperModule,
    RxLet,
    NgxPageScrollCoreModule,
    YouTubePlayerModule
  ],
  exports: [
    DefaultDatePipe,
    CountToDatePipe,
    TrustedUrlPipe,
    TrustedHtmlPipe,
    HighlightPipe,
    TruncatePipe,
    RemoveByTagsPipe,
    FilesizePipe,

    // Components
    CvListElementComponent,
    CheckboxCategoryComponent,
    CheckboxesCategoriesDetailsComponent,
    CheckboxesCategoriesEditComponent,
    CheckboxCategoryListItemComponent,
    ImagePreloaderComponent,
    LabeledEditComponent,
    PhonesEditComponent,
    AddressEditComponent,
    LocationEditComponent,
    PrintSettingsComponent,
    PrintSettingsDialogComponent,
    ImagePickerComponent,
    CustomUploadComponent,
    PrintPreviewDialogComponent,
    PopoverComponent,
    SkillsSelectorComponent,
    CvPrimaryDialogComponent,
    ConsultantCvPrimaryDialogComponent,
    SecureAvatarComponent,
    FilesUploadComponent,
    FilesUploadElementComponent,
    FileUploadComponent,
    FileUploadElementComponent,
    FileUploadPdfComponent,
    FileUploadPdfElementComponent,
    SystemMessagesComponent,
    TestimonialComponent,
    EventBoxComponent,
    EventBoxElementComponent,
    CvParseComponent,
    CvParseDialogComponent,
    VideoPlayerComponent,
    SharepointGalleryComponent,
    SharepointGalleryThumbComponent,

    // Directives
    AuthCanDirective,
    AuthCanSomeDirective,
    AuthCantSomeDirective,
    AuthCantDirective,
    IfModuleEnabledDirective,
    Nav2ndLevelHostDirective,
    MatButtonLoadingDirective,
    InputSanitizeControlCharactersDirective,
    GalleryPhotoDirective,
    ScrollToErrorDirective,
    IsElementVisibleDirective,

    // common modules
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    My7nMaterialModule,
    NgSelectModule,
    EditorModule,
    CalendarModule,
    My7nUiModule,
    My7nSharedModule,
    PdfJsViewerModule,
    ClipboardModule,
    RxLet,
    YouTubePlayerModule
  ],
  declarations: [
    DefaultDatePipe,
    CountToDatePipe,
    TrustedUrlPipe,
    TrustedHtmlPipe,
    HighlightPipe,
    TruncatePipe,
    RemoveByTagsPipe,
    FilesizePipe,

    // Components
    CvListElementComponent,
    CheckboxCategoryComponent,
    CheckboxesCategoriesDetailsComponent,
    CheckboxesCategoriesEditComponent,
    CheckboxCategoryListItemComponent,
    ImagePreloaderComponent,
    LabeledEditComponent,
    PhonesEditComponent,
    AddressEditComponent,
    LocationEditComponent,
    PrintSettingsComponent,
    PrintSettingsDialogComponent,
    ImagePickerComponent,
    CustomUploadComponent,
    PrintPreviewDialogComponent,
    PopoverComponent,
    SkillsSelectorComponent,
    CvPrimaryDialogComponent,
    ConsultantCvPrimaryDialogComponent,
    SecureAvatarComponent,
    FilesUploadComponent,
    FilesUploadElementComponent,
    FileUploadComponent,
    FileUploadElementComponent,
    FileUploadPdfComponent,
    FileUploadPdfElementComponent,
    SystemMessagesComponent,
    TestimonialComponent,
    EventBoxComponent,
    EventBoxElementComponent,
    CvParseComponent,
    CvParseDialogComponent,
    VideoPlayerComponent,
    SharepointGalleryComponent,
    SharepointGalleryThumbComponent,


    // Directives
    AuthCanDirective,
    AuthCanSomeDirective,
    AuthCantSomeDirective,
    AuthCantDirective,
    IfModuleEnabledDirective,
    Nav2ndLevelHostDirective,
    MatButtonLoadingDirective,
    InputSanitizeControlCharactersDirective,
    GalleryPhotoDirective,
    ScrollToErrorDirective,
    IsElementVisibleDirective
  ],
  providers: [
    PrintSettingsService,
    MatMenuCustomService,
    FilesizePipe,
    ImagePreloaderService,
    ScrollToElementService,
    DownloadService,
    SecureImageService,
    GalleryFacadeService,
    GlobalFacadeService,
    NotificationsFacade,
    DictionariesFacadeService,
    DictionariesService,
    FabAgentService,
    FeedbackService,
    MessageBusService,
    PreviewService,
    PrintService,
    QueryParamsFeatureEnablerService,
    ReactionsService,
    StorageCacheService,
    UserProfileService
  ],
  bootstrap: [
    PrintSettingsDialogComponent,
    PrintPreviewDialogComponent,
    CvPrimaryDialogComponent,
    ConsultantCvPrimaryDialogComponent
  ]
})
export class My7nCommonModule { }
