<nav #navigationMain>
  <mat-toolbar>
    <mat-toolbar-row class="mat-toolbar-row-padding flex flex-start-stretch">
      <div class="navigation-logo flex flex-start-center max-sm:hidden" 
           data-test-id="navigation-logo">
        <a class="flex" routerLink="/"><img src="assets/images/logo-my7n.svg" alt="my7N Logo" /></a>
      </div>
      <div (click)="toggleNavigation()"
           [hidden]="!activeLink"
           class="navigation-button flex flex-start-center"
           data-test-id="navigation-button" 
           #navigationButton
           [ngClass]="{navigationOpened}">{{activeLink?.title}}</div>
      <div class="flex flex-end-center flex-1">
        <popover-notifications *authCan="instantNotificationFeature" 
                               [unseenNotificationsCounter]="totalUnseenNotifications$ | async" 
                               [lastReadTimestamp]="notificationsRequestTimestamp$ | async"
                               data-test-id="navigation-popover-notifications">
          <ng-template cdkPortal #popoverContent="cdkPortal" let-popover>
            <notifications [notifications]="notifications$ | async" 
                           [popoverInstance]="popover" 
                           data-test-id="navigation-notifications"></notifications>
          </ng-template>
        </popover-notifications>
        <navigation-menu class="flex" 
                         [user]="currentUser" 
                         data-test-id="navigation-navigation-menu"></navigation-menu>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div [@openCloseNav]="navigationOpened ? 'opened' : 'closed'"
       class="navigation-container" 
       #navigationContainer
       data-test-id="navigation-navigation-container"
       [ngClass]="{navigationOpened}">
      <navigation-content data-test-id="navigation-navigation-content"></navigation-content>
  </div>
</nav>
