import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, of, switchMap } from 'rxjs';
import * as fromGlobal from '../../store/reducers/global/index';
import * as GlobalActions from '../../store/actions/global.actions';
import { BusinessUnit } from '../../interfaces/business-unit';
import { IUserPreferences } from '../../interfaces/user-preferences';
import { ITokenClaims } from '../../interfaces/token-claims';
import { IPrintPreferences } from '../../interfaces/print-preferences';
import { IBlobSasTokens } from '../../interfaces/blob-sas';
import {
  LEGAL_ENTITY_COUNTRY_FEATURE_PREFIX,
  LegalEntityFeatures
} from '@my7n/features';

@Injectable()
export class GlobalFacadeService {
  constructor(private store: Store<fromGlobal.State>) {}

  get appVersion$(): Observable<string> {
    return this.store.pipe(select(fromGlobal.selectAppVersion));
  }

  get userBusinessUnit$(): Observable<BusinessUnit> {
    return this.store.pipe(select(fromGlobal.selectTokenClaimsBusinessUnit));
  }

  get leavingPageGuardEnabled$(): Observable<boolean> {
    return this.store.pipe(select(fromGlobal.selectLeavingPageGuardEnabled));
  }

  get user$(): Observable<IUserPreferences> {
    return this.store.pipe(select(fromGlobal.selectAppConfigUser));
  }

  get tokenClaims$(): Observable<ITokenClaims> {
    return this.store.pipe(select(fromGlobal.selectTokenClaims));
  }

  get printPreferences$(): Observable<IPrintPreferences> {
    return this.store.pipe(select(fromGlobal.selectPrintPreferences));
  }

  get blobSasTokens$(): Observable<IBlobSasTokens> {
    return this.store.pipe(select(fromGlobal.selectBlobSasTokens));
  }

  get legalEntityCountryFeature$(): Observable<LegalEntityFeatures> {
    return this.user$.pipe(
      switchMap((user) => {
        const legalEntityFeatures: Array<LegalEntityFeatures> =
          user.Privileges?.filter((p) =>
            p.startsWith(LEGAL_ENTITY_COUNTRY_FEATURE_PREFIX)
          );
        if (!legalEntityFeatures || legalEntityFeatures.length === 0) {
          // this case should be visible in logs - it means that the user account is not configured properly
          console.error(
            '[GlobalFacadeService] Legal entity country not provided in user privileges'
          );
        }
        if (legalEntityFeatures.length > 1) {
          // this case should be visible in logs - it means that the user account is not configured properly
          console.error(
            '[GlobalFacadeService] Multiple legal entity countries provided in user privileges'
          );
        }
        return of(legalEntityFeatures[0]); // return first one
      })
    );
  }

  get userPrivileges$(): Observable<string[]> {
    return this.user$.pipe(
      switchMap((user) => {
        return of(user.Privileges);
      })
    );
  }

  updateAppVersion(version: string) {
    this.store.dispatch(
      GlobalActions.updateAppVersion({
        appVersion: version
      })
    );
  }

  queryAppConfig() {
    this.store.dispatch(GlobalActions.queryAppConfig());
  }

  updateLeavingPageGuardEnabled(isEnabled: boolean) {
    this.store.dispatch(
      GlobalActions.updateLeavingPageGuardEnabled({
        isEnabled
      })
    );
  }

  updateTokenClaims(claims: ITokenClaims) {
    this.store.dispatch(
      GlobalActions.updateTokenClaims({ tokenClaims: claims })
    );
  }

  updatePrintPreferences(settings: IPrintPreferences) {
    this.store.dispatch(
      GlobalActions.updatePrintPreferences({
        settings
      })
    );
  }

  queryBlobSasTokens() {
    this.store.dispatch(GlobalActions.queryBlobSasTokens());
  }
}
