import * as fromApp from '../index';
import * as fromGlobal from './global.reducer';
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

export const globalFeatureKey = 'globalModule';
export const globalStateKey = 'global';

export interface State {
  global: fromGlobal.State;
}

export interface FeatureState extends fromApp.State {
  global: State;
}

export const reducers: ActionReducerMap<State> = {
  global: fromGlobal.globalReducer
};

// Global module selectors
export const getGlobalModuleState =
  createFeatureSelector<State>(globalFeatureKey);

export const getGlobalState =
  createFeatureSelector<fromGlobal.State>(globalStateKey);

export const selectGlobalState = createSelector(
  getGlobalModuleState,
  getGlobalState
);

export const selectAppVersion = createSelector(
  selectGlobalState,
  fromGlobal.getAppVersion
);

export const selectAppConfig = createSelector(
  selectGlobalState,
  fromGlobal.getAppConfig
);

export const selectAppConfigUser = createSelector(
  selectGlobalState,
  fromGlobal.getAppConfigUser
);

export const selectLeavingPageGuardEnabled = createSelector(
  selectGlobalState,
  fromGlobal.getLeavingPageGuardEnabled
);

export const selectTokenClaims = createSelector(
  selectGlobalState,
  fromGlobal.getTokenClaims
);

export const selectTokenClaimsBusinessUnit = createSelector(
  selectGlobalState,
  fromGlobal.getTokenClaimsBusinessUnit
);

export const selectPrintPreferences = createSelector(
  selectGlobalState,
  fromGlobal.getPrintPreferences
);

export const selectBlobSasTokens = createSelector(
  selectGlobalState,
  fromGlobal.getBlobSasTokens
);
