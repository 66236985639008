import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  switchMap
} from 'rxjs/operators';
import { SnackBarService, SnackBarTypes, Icons } from '@my7n/ui';
import * as VideoUpdateActions from '../../../store/actions/cms/video-update.actions';
import * as fromCms from '../../reducers/cms/index';
import * as videoUpdateSelectors from '../../reducers/cms/video-update.selectors';
import { ICmsVideoUpdateItem } from '../../../interfaces/cms';
import { VideoUpdateService } from '../../../services/video-update.service';
import * as ngrxUtils from '../../../utils/ngrx-utils';
import { GlobalFacadeService } from '../../../services/facades/global-facade.service';

@Injectable()
export class VideoUpdateEffects {
  constructor(
    private actions$: Actions,
    private videoUpdateService: VideoUpdateService,
    private snackBarService: SnackBarService,
    private store$: Store<fromCms.FeatureState>,
    private globalFacadeService: GlobalFacadeService
  ) {}

  queryLatestVideoUpdateByUserLegalEntityCountryFeature$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(VideoUpdateActions.queryLatestVideoUpdateByUserLegalEntityCountryFeature),
      concatLatestFrom(() => [
        this.store$.select(videoUpdateSelectors.selectLatestVideoUpdate),
        this.store$.select(
          videoUpdateSelectors.selectLatestVideoUpdateRequestTimestamp
        ),
        this.globalFacadeService.legalEntityCountryFeature$
      ]),
      distinctUntilChanged(),
      switchMap(([action, state, lastRequestTimestamp, legalEntityCountryFeature]) => {
        if (state !== null && ngrxUtils.isInCacheLimit(lastRequestTimestamp)) {
          return of(VideoUpdateActions.latestVideoUpdateLoadedFromCache());
        } else {
          return this.videoUpdateService.getLatestVideoUpdateByLegalEntityFeature(legalEntityCountryFeature).pipe(
            map((res: ICmsVideoUpdateItem) => {
              return VideoUpdateActions.latestVideoUpdateLoaded({
                latestVideoUpdate: res
              });
            }),
            catchError(() => {
              this.snackBarService.open({
                message:
                  'An error occurred during loading Latest Video Update data.',
                type: SnackBarTypes.ErrorAlt,
                actionIcon: Icons.CLOSE_TINY
              });
              return of(
                VideoUpdateActions.queryLatestVideoUpdateByUserLegalEntityCountryFeatureError()
              );
            })
          );
        }
      })
    );
  });

  queryVideoUpdatePreviewById$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(VideoUpdateActions.queryVideoUpdatePreviewById),
      distinctUntilChanged(),
      switchMap((action) => {
        return this.videoUpdateService
          .getVideoUpdatePreviewById(action.id)
          .pipe(
            map((res: ICmsVideoUpdateItem) => {
              return VideoUpdateActions.latestVideoUpdateLoaded({
                latestVideoUpdate: res
              });
            }),
            catchError(() => {
              this.snackBarService.open({
                message:
                  'An error occurred during loading Latest Video Update data.',
                type: SnackBarTypes.ErrorAlt,
                actionIcon: Icons.CLOSE_TINY
              });
              return of(VideoUpdateActions.querVideoUpdatePreviewByIdError());
            })
          );
      })
    );
  });
}
