import { Action, createReducer, on } from '@ngrx/store';
import * as fromMy7n from '../index';
import * as GlobalActions from '../../actions/global.actions';
import { IAppConfig } from '../../../interfaces/app-config';
import { ITokenClaims } from '../../../interfaces/token-claims';
import { IBlobSasTokens } from '../../../interfaces/blob-sas';

export interface FeatureState extends fromMy7n.State {
  global: State;
}

export interface State {
  appVersion: string;
  appConfig: IAppConfig;
  leavingPageGuardEnabled: boolean;
  tokenClaims: ITokenClaims;
  blobSasTokens: IBlobSasTokens;
}

export const initialState: State = {
  appVersion: '0.0.0',
  appConfig: null,
  leavingPageGuardEnabled: false,
  tokenClaims: null,
  blobSasTokens: null
};

const _globalReducer = createReducer(
  initialState,
  on(GlobalActions.updateAppVersion, (state, { appVersion }) => ({
    ...state,
    appVersion: appVersion
  })),
  on(GlobalActions.appConfigLoaded, (state, { appConfig }) => ({
    ...state,
    appConfig: appConfig
  })),
  on(GlobalActions.updateLeavingPageGuardEnabled, (state, { isEnabled }) => ({
    ...state,
    leavingPageGuardEnabled: isEnabled
  })),
  on(GlobalActions.updateTokenClaims, (state, { tokenClaims }) => ({
    ...state,
    tokenClaims
  })),
  on(GlobalActions.updatePrintPreferences, (state, { settings }) => ({
    ...state,
    appConfig: {
      ...state.appConfig,
      User: { ...state.appConfig.User, PrintPreferences: settings }
    }
  })),
  on(GlobalActions.queryBlobSasTokensSuccess, (state, { blobSasTokens }) => ({
    ...state,
    blobSasTokens
  }))
);

export function globalReducer(state: State | undefined, action: Action) {
  return _globalReducer(state, action);
}

export const getAppVersion = (state: State) => {
  return state.appVersion;
};

export const getAppConfig = (state: State) => {
  return state.appConfig;
};

export const getLeavingPageGuardEnabled = (state: State) => {
  return state.leavingPageGuardEnabled;
};

export const getAppConfigUser = (state: State) => {
  return state.appConfig.User;
};

export const getTokenClaims = (state: State) => {
  return state.tokenClaims;
};

export const getTokenClaimsBusinessUnit = (state: State) => {
  return state.tokenClaims.BusinessUnit;
};

export const getPrintPreferences = (state: State) => {
  return state.appConfig?.User?.PrintPreferences;
};

export const getBlobSasTokens = (state: State) => {
  return state.blobSasTokens;
};
