import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppConfig } from '../app-config';
import { catchError, lastValueFrom, Observable, throwError } from 'rxjs';
import { ServiceNames } from '../interfaces/my7n-env-config';
import {IConsultantsTenantDetails, IUpdateAvatarResponse} from '../interfaces/user-profile';
import { IBlobSasTokens } from '../interfaces/blob-sas';

@Injectable()
export class UserService {
  API_USER: string;

  constructor(private http: HttpClient,
              private appConfig: AppConfig) {
                const userApiPrefix = this.appConfig.serviceUrl(ServiceNames.User, 'v1');
                this.API_USER =  userApiPrefix + 'user/';
              }


  getUserPreferences(): Promise<object> {
    // https://rxjs.dev/deprecations/to-promise
    return lastValueFrom(this.http.get(this.appConfig.CONFIG_API_URLS.userPreferences));
  }

  requestTransfer(): Observable<void> {
    return this.http
     .post<void>(`${this.API_USER}consultant/project-change-request`, {}).pipe(
       catchError((error: HttpErrorResponse) => {
         console.error(
           `[UserService] Failed to send transfer request`,
           error
         );
         return throwError(() => error);
       })
     );
  }

  private updatePortrait(portraitData: string, endpoint: string): Observable<IUpdateAvatarResponse> {
    return this.http.patch<IUpdateAvatarResponse>(endpoint, { PortraitBase64: portraitData }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(
          `[UserService] Failed to update portrait`,
          error
        );
        return throwError(() => error);
      })
    )
  }

  updatePortraitForAgent(portraitData: string, userId: string): Observable<IUpdateAvatarResponse> {
    const endpoint = `${this.API_USER}personal-details/${userId}/portrait`;
    return this.updatePortrait(portraitData, endpoint);
  }

  updatePortraintForConsultant(portraitData: string): Observable<IUpdateAvatarResponse> {
    const endpoint = `${this.API_USER}personal-details/portrait`;
    return this.updatePortrait(portraitData, endpoint);
  }


  private removePortrait(endpoint: string): Observable<void> {
    return this.http.delete<void>(endpoint).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(
          `[UserService] Failed to remove portrait`,
        );
        return throwError(() => error);
      })
    )
  }

  removePortraitForAgent(userId: string): Observable<void> {
    const endpoint = `${this.API_USER}personal-details/${userId}/portrait`;
    return this.removePortrait(endpoint);
  }

  removePortraintForConsultant(): Observable<void> {
    const endpoint = `${this.API_USER}personal-details/portrait`;
    return this.removePortrait(endpoint);
  }

  getBlobSasTokens(): Observable<IBlobSasTokens> {
    return this.http
      .get<IBlobSasTokens>(
        `${this.API_USER}blob-sas`
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(
            `[User Service] Failed to retrieve blob sas tokens`,
            error
          );
          return throwError(() => error);
        })
      );
  }

  logSuccessfulLogin(): Observable<void> {
    return this.http
      .post<void>(`${this.API_USER}activity/login-successful`, {})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(
            `[User Service] Failed to log successful login`,
            error
          );
          return throwError(() => error);
        })
      );
  }

  logClaimedBenefit(benefitId: string, benefitName: string): Observable<void> {
    return this.http
      .post<void>(`${this.API_USER}activity/claimed-benefit`, { BenefitId: benefitId, BenefitName: benefitName })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(
            `[User Service] Failed to log claimed benefit`,
            error
          );
          return throwError(() => error);
        })
      );
  }

  /**
   * Returns user details from Consultants tenant
   */
  getConsultantsTenantDetails(): Observable<IConsultantsTenantDetails> {
    return this.http.get<IConsultantsTenantDetails>(`${this.API_USER}account/consultants/details`).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(
          `[UserService] Failed to get consultants tenant details`,
          error
        );
        return throwError(() => error);
      })
    );
  }
}
