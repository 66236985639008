import { Injectable } from '@angular/core';
import { AppConfig } from '../app-config';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { Observable, catchError, throwError } from 'rxjs';
import {
  ContentTypes,
  IContentReactionsItem,
  IContentReactionsResponse
} from '../interfaces/reactions';
import { CmsContentTypes } from '../interfaces/cms';

@Injectable()
export class ReactionsService {
  private API_REACTIONS: string;

  constructor(private appConfig: AppConfig, private http: HttpClient) {
    const keyValueStorageApiPrefix = this.appConfig.serviceUrl(
      ServiceNames.KeyValueStorage,
      'v1'
    );
    this.API_REACTIONS =
      keyValueStorageApiPrefix + 'key-value-storage/content-reactions';
  }

  getContentReactions(
    contentId: string | number,
    contentType: ContentTypes
  ): Observable<IContentReactionsResponse> {
    const options = {
      params: new HttpParams()
        .set('ContentId', contentId.toString())
        .set('ContentType', contentType)
    };
    return this.http
      .get<IContentReactionsResponse>(this.API_REACTIONS, options)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(
            `[ReactionsService] Failed to retrieve Reactions for content id ${contentId} and content type ${contentType}`,
            error
          );
          return throwError(() => error);
        })
      );
  }

  updateContentReactionsItem(
    contentId: string | number,
    contentType: ContentTypes,
    reaction: Partial<IContentReactionsItem>
  ): Observable<boolean> {
    const body = {
      ContentId: contentId?.toString(),
      ContentType: contentType,
      Reaction: reaction.ReactionType !== null ? reaction : null // if reaction type is null, the reaction will be cleared from the table
    };
    return this.http.put<boolean>(`${this.API_REACTIONS}/reaction`, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(
          `[ReactionsService] Failed to update Reaction for content id ${contentId} and content type ${contentType}`,
          error
        );
        return throwError(() => error);
      })
    );
  }

  getContentTypeFromCmsContentType(
    cmsContentType: CmsContentTypes
  ): ContentTypes {
    switch (cmsContentType) {
      case CmsContentTypes.AcademyBookItem:
        return ContentTypes.MY7N_ACADEMY_BOOK;
      case CmsContentTypes.AcademyOfferItem:
        return ContentTypes.MY7N_BENEFIT;
      case CmsContentTypes.AcademyVideo:
        return ContentTypes.MY7N_ACADEMY_VIDEO;
      case CmsContentTypes.News:
        return ContentTypes.MY7N_NEWS;
      default:
        return null;
    }
  }
}
