<navigation></navigation>
<div class="main-container-wrapper" *ngIf="isAuthenticated$ | async">
  <main id="main-container">
    <div class="view-loader full-screen flex flex-col flex-center-center flex-auto"
         [ngClass]="{'full-screen': firstView}"
         *ngIf="loading">
      <loader color="primary"></loader>
    </div>
    <div class="main-view" [ngClass]="{loading: loading}" [@routerOutletVisibility]="loading ? 'hidden': 'shown'">
      <custom-router-outlet></custom-router-outlet>
    </div>
  </main>
</div>
<system-messages></system-messages>
<div class="fab-container">
  <div class="chat-bot-container">
    <chat-bot-button *authCan="chatbotFeature"
                     [disabled]="chatBotDisabled"
                     [message]="chatBotMessage"
                     [hidden]="chatBotHidden"
                     [showMessage]="chatBotShowMessage"
                     [avatar]="secureAvatar.avatarInstance"
                     title="{{CurrentUser?.Agent?.FirstName}}">
      <secure-avatar [avatarUrl]="CurrentUser?.Agent?.PortraitUrl" [title]="CurrentUser?.Agent?.FirstName" [plugEnabled]="false" #secureAvatar></secure-avatar>
    </chat-bot-button>
  </div>
</div>
