import { Injectable, OnDestroy } from '@angular/core';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ITimelineNewsResponse, ITimelineEventsResponse } from '../interfaces/news-response';
import { INewsList } from '../interfaces/news';
import { AppConfig } from '../app-config';
import { takeUntil } from 'rxjs/operators';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { MediaQueryService } from '@my7n/ui';

@Injectable()
export class TimelineService implements OnDestroy {
  readonly API_TIMELINE: string;
  readonly API_EVENTS: string;

  /**
   * Flag that indicates whether current resolution id mobile or not.
   */
  isMobile = false;

  /**
   * Returns maximum length of news title that may be displayed on mobile/desktop version
   */
  get titleTruncateLimit(): number {
    return this.isMobile ? 45 : 100;
  }

  /**
   * Returns maximum length of news description that may be displayed on mobile/desktop version
   */
  get descriptionTruncateLimit(): number {
    return this.isMobile ? 100 : 280;
  }

  private _destroySubject$: Subject<void> = new Subject();

  constructor(private http: HttpClient,
              private mediaQueryService: MediaQueryService,
              private appConfig: AppConfig) {
    this.mediaQueryService.breakpoints$
      .pipe(takeUntil(this._destroySubject$))
      .subscribe(({ ltMd }) => {
        this.isMobile = ltMd;
      });

    this.API_TIMELINE = this.appConfig.serviceUrl(ServiceNames.Timeline, 'v1') + 'timeline';
    this.API_EVENTS = this.appConfig.serviceUrl(ServiceNames.Events, 'v1') + 'events';
  }

  getYammerNews(newsId: number): Promise<object> {
    const API_RESOURCE = `${this.API_TIMELINE}/news/${newsId}`;

    return lastValueFrom(this.http.get<INewsList>(API_RESOURCE));
  }

  getEventsList(offset = 0): Observable<ITimelineEventsResponse> {
    const API_RESOURCE = `${this.API_EVENTS}/timeline?offset=${offset}`;

    return this.http.get<ITimelineEventsResponse>(API_RESOURCE);
  }

  getYammerNewsList(offset = 0): Observable<ITimelineNewsResponse> {
    const API_RESOURCE = `${this.API_TIMELINE}/news?offset=${offset}`;

    return this.http.get<ITimelineNewsResponse>(API_RESOURCE);
  }

  ngOnDestroy() {
    this._destroySubject$.next();
  }
}
