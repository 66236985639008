import { Inject, Injectable, OnDestroy } from '@angular/core';
import { AuthorizationService } from './services/authorization.service';
import { HttpClient } from '@angular/common/http';
import { MY7N_ENV_CONFIG } from './functions/my7n-env-config';
import { BehaviorSubject, Observable, Subject, from, lastValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';

// interfaces
import { IMy7nEnvConfig, ServiceNames } from './interfaces/my7n-env-config';
import { IAllowedFeaturesResponse, IAppConfig } from './interfaces/app-config';
import { IUserPreferences } from './interfaces/user-preferences';
import { ICvLanguage } from './interfaces/cv-language';
import { ITermsAndConditions } from './interfaces/user-profile';

interface IConfigApiUrls {
  timekeys: string;
  userPreferences: string;
  languages: string;
  accessFeatures: string;
  terms: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppConfig implements OnDestroy {
  public readonly CONFIG_API_URLS: IConfigApiUrls;
  public static readonly DEFAULT_DATE_OUTPUT_FORMATS = {
    'second': 'DD MMM YYYY, HH:mm:ss',
    'minute': 'DD MMM YYYY, HH:mm',
    'day': 'DD MMM YYYY',
    'month': 'MMM YYYY',
    'year': 'YYYY',
    'time': 'HH:mm',
    'short_month': 'MMM',
    'short_day_of_week': 'ddd',
    'two_digits_day': 'DD'
  };

  public static readonly imagesPath: string = 'assets/images';

  public static readonly disabledModules: string[] = [];

  // @TODO before adding another service, consider if this should not be moved to backend
  // Check [ADR 0005] to know why we are appending privileges on frontend
  private readonly commonPrivilegesList: Array<string> = [
    'v1/static-content/*'
  ];

  private config: IAppConfig = {
    // should be set during init process
    TimeKeys: {},

    // should be set during init process
    User: undefined,

    // should be set during init process
    Languages: undefined
  };

  private dirty = false;
  private _unsubscribe$: Subject<void> = new Subject<void>();

  get initialized(): boolean {
    return this._initialized$.value;
  }

  get initialized$(): Observable<boolean> {
    return this._initialized$.asObservable();
  }

  private _initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private get wasInitialized$(): Observable<boolean> {
    return this._initialized$.asObservable().pipe(
      // emit only when init is true, else wait
      filter((wasInitialized: boolean) => {
        return wasInitialized === true;
      })
    );
  }

  private initializationPromise: Promise<IAppConfig> = null;
  private changesSubject: Subject<IAppConfig> = new Subject();

  constructor(private http: HttpClient,
    private authorizationService: AuthorizationService,
    @Inject(MY7N_ENV_CONFIG) private envConfig: IMy7nEnvConfig) {

    this.CONFIG_API_URLS = {
      'timekeys': this.serviceUrl(ServiceNames.Cv, 'v1') + 'cv/dictionaries/timekeys',
      'userPreferences': this.serviceUrl(ServiceNames.User, 'v1') + 'user',
      'languages': this.serviceUrl(ServiceNames.Cv, 'v1') + 'cv/dictionaries/languages',
      'accessFeatures': this.serviceUrl(ServiceNames.Identity, 'v1') + 'identity/allowed-features',
      'terms': this.serviceUrl(ServiceNames.Gdpr, 'v1') + 'gdpr/consent/terms',
    };
  }

  init() {
    if (this.initializationPromise === null) {
      this.initializationPromise = this.reload();

      return this.initializationPromise.then((response) => {
        this._initialized$.next(true);
        this.initializationPromise = null;

        return response;
      }, (rejection) => {
        console.error('[My7NAppConfig] Something went wrong while initializing my7N config', rejection);
        return Promise.reject(rejection);
      });
    }

    return this.initializationPromise;
  }

  invalidate() {
    this.dirty = true;
  }

  reload(): Promise<IAppConfig> {
    const configApiUrls = Object.keys(this.CONFIG_API_URLS).map((key) => {
      return lastValueFrom(this.http.get(this.CONFIG_API_URLS[key]));
    });

    return Promise.all(configApiUrls).then((response: Array<any>) => {
      let regexPrivileges;
      const privileges = [];

      // Set Timekeys
      Object.keys(response[0]).forEach((timekey) => {
        // Remove TimeKey from key
        this.config.TimeKeys[timekey.replace('TimeKey', '')] = response[0][timekey];
      });

      this.config.User = <IUserPreferences>response[1];
      this.config.User.AcceptStoringAndSendingDataBy7n = (<ITermsAndConditions>response[4]).AcceptStoringAndSendingDataBy7n;
      this.config.User.AcceptedMy7nEventInvitation = (<ITermsAndConditions>response[4]).AcceptMy7nEventInvitation;
      this.config.User.AcceptedMy7nNewsletter = (<ITermsAndConditions>response[4]).AcceptMy7nNewsletter;
      this.config.User.Privileges = this.config.User.Privileges || [];

      // Check [ADR 0005] to know why we are appending privileges on frontend
      this.appendCommonPrivileges(this.config.User.Privileges);

      // First add privileges ending with '/*' to strict privileges, but without '/*' ending
      // this will allow to match endpoints like api/cv and api/cv/2 but no api/cvaaaaaaaaa/s
      this.config.User.Privileges.forEach(function (privilege) {
        if (privilege.substr(-2) === '/*') {
          // Remove /* or * char at the end
          privileges.push(privilege.replace('/*', ''));
        }
      });

      // all feature privileges that we got from identity service are added also to privileges
      privileges.push(...(<IAllowedFeaturesResponse>response[3]).AllowedFeatures);

      // append the original privileges also
      Array.prototype.push.apply(privileges, this.config.User.Privileges);

      // Define regex privileges. {id} strings are replaced with regex
      regexPrivileges = privileges.slice().filter((privilege) => {
        return privilege.indexOf('{id}') > -1;
      }).map(function (privilege) {
        const appendEndChar = privilege[privilege.length - 1] !== '*',
          // Remove /* or * char at the end and replace {id} with regex for numbers between 0-9+
          cleanPrivilege = privilege.replace(/(\*|\/\*)$/, '').replace(/{id}/g, '([0-9]+)|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})');

        return new RegExp('^' + cleanPrivilege + (appendEndChar ? '$' : ''), 'i');
      });

      this.config.User.Privileges = privileges;
      this.config.User.RegexPrivileges = regexPrivileges;

      this.config.Languages = response[2].Languages.map((language: ICvLanguage) => {
        language.FlagUrl = AppConfig.imagesPath + `/flags/${language.LanguageCode}.svg`;

        return language;
      });

      this.dirty = false;

      // Initialize auth service with new user config
      this.authorizationService.init(this.config.User);

      this.changesSubject.next(this.config);

      return this.config;
    }, (error) => {
      console.error('[My7NAppConfig] Error occurred during requiring for configuration');
      return Promise.reject(error);
    });
  }

  serviceUrl(serviceName: ServiceNames = ServiceNames.Core, version: string = 'v2'): string {
    if (!this.envConfig.services[serviceName]) {
      console.error(`[AppConfig] service ${serviceName} is missing in configuration, empty string returned`);
      return '';
    }

    // Add trailing slash if it's missing
    let serviceUrl =  `${this.envConfig.services[serviceName].replace(/\/?$/, '/')}`;

    // optional API version
    if (version) {
      serviceUrl += version + '/';
    }

    return serviceUrl;
  }

  get<T>(key: string): Promise<T> {
    if (this.initialized && !this.dirty) {
      return new Promise<T>((resolve, reject) => {
        return resolve(this.findByKey<T>(key));
      });
    }

    return lastValueFrom(this.wasInitialized$.pipe(take(1)))
      .then(() => {
        return this.findByKey<T>(key);
      });
  }

  set(key: string, value): Promise<true> {
    if (this.initialized && !this.dirty) {
      return new Promise(resolve => {
        this.setByKey(key, value);
        resolve(true);
      });
    }

    return lastValueFrom(this.wasInitialized$.pipe(take(1)))
      .then(() => {
        this.setByKey(key, value);
        return true;
      });
  }

  getAll(): Promise<IAppConfig> {
    if (this.initialized && !this.dirty) {
      return new Promise(resolve => {
        resolve(this.config);
      });
    }

    return lastValueFrom(this.wasInitialized$.pipe(take(1)))
      .then(() => {
        return this.config;
      });
  }

  getAll$(): Observable<IAppConfig> {
    return from(this.getAll());
  }

  configChanges(): Subject<IAppConfig> {
    return this.changesSubject;
  }

  private findByKey<T>(key: string): T {
    let keyParts,
      tempObj;

    if (key.indexOf('.') === -1) {
      return this.config[key];
    }

    keyParts = key.split('.');
    tempObj = this.config;

    while (keyParts.length > 0 && tempObj) {
      tempObj = tempObj[keyParts.shift()];
    }

    return tempObj;
  }

  private setByKey(key, value) {
    let keyParts,
      tempObj,
      currentKey;

    keyParts = key.split('.');
    tempObj = this.config;

    while (keyParts.length > 1) {
      currentKey = keyParts.shift();
      if (tempObj[currentKey] === undefined) {
        tempObj[currentKey] = {};
      }

      tempObj = tempObj[currentKey];
    }

    tempObj[keyParts.shift()] = value;
  }

  private appendCommonPrivileges(refToListOfPrivileges: Array<string>) {
    Array.prototype.push.apply(refToListOfPrivileges, this.commonPrivilegesList);
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
  }
}
