import { Injectable } from '@angular/core';
import { AppConfig } from '../app-config';

@Injectable()
export class FabAgentService {

  constructor(private config: AppConfig) { }

  getReminder() {
    //return this.config.get('User.HasToUpdateContactData');
    //@TODO FabAgent.service.js, set real flag to hasToUpdateContactData

    return Promise.resolve(false);
  }

  remindLater() {
    return this.config.set('User.HasToUpdateContactData', true);
  }

}
