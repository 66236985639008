import { Injectable } from '@angular/core';
import { AppConfig } from '../app-config';
// services
import { StorageCacheService } from './storage-cache.service';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { IPrivateAddressCountryResponse } from '../interfaces/dictionaries';

@Injectable()
export class DictionariesService {
  /**
   * Dictionaries API prefix.
   * @type {string}
   */
  readonly DICT_API_PREFIX: string;

  constructor(private config: AppConfig,
              private storageCacheService: StorageCacheService,
              private appConfig: AppConfig) {
    this.DICT_API_PREFIX = this.appConfig.serviceUrl(ServiceNames.Cv, 'v1') + 'cv/dictionaries/';
  }

  getAllMainCompetenceAreas() {
    return this.storageCacheService.get<any>(this.DICT_API_PREFIX + 'competenceareas', this.config.get('TimeKeys.CompetenceAreas')).then((result) => {
      return result.CompetenceAreas;
    }).catch((error) => {
      console.error('[DictionariesService] Failed to get Competence Areas', error);
      return Promise.reject(error);
    });
  }

  getAllWorkRoles() {
    return this.storageCacheService.get<any>(this.DICT_API_PREFIX + 'workroles', this.config.get('TimeKeys.WorkRoles')).then((result) => {
      return result.WorkRoles;
    }).catch((error) => {
      console.error('[DictionariesService] Failed to get WorkRoles', error);
      return Promise.reject(error);
    });
  }

  getAllIndustryFocuses() {
    return this.storageCacheService.get<any>(this.DICT_API_PREFIX + 'industryknowledge', this.config.get('TimeKeys.IndustryFocus')).then((result) => {
      return result.IndustryFocus;
    }).catch((error) => {
      console.error('[DictionariesService] Failed to get Industry Focuses', error);
      return Promise.reject(error);
    });
  }

  getAllPrivateAddressCountries(): Promise<IPrivateAddressCountryResponse> {
    return this.storageCacheService.get<IPrivateAddressCountryResponse>(this.DICT_API_PREFIX + 'countries', this.config.get('TimeKeys.Countries')).then((result) => {
      return result;
    }).catch((error) => {
      console.error('[DictionariesService] Failed to get private countries addresses', error);
      return Promise.reject(error);
    });
  }
}
