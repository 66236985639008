import { Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { AppConfig } from '../app-config';

/**
 * This guard checks if module is blocked in the main app config file.
 * In such case module will not be loaded.
 */
@Injectable()
export class ModuleEnabledGuard  {

  public readonly DISABLED_MODULES_CFG_KEY = 'disabledModules';

  constructor() {}

  canLoad(route: Route): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        // check if module is disabled
        const disabledModule: boolean = AppConfig[this.DISABLED_MODULES_CFG_KEY].some((disabledModule) => disabledModule === route.path);
        // return negation of found result
        resolve(!disabledModule);
      } catch (e) {
        reject(e);
      }
    });
  }
}
