import { createAction, props } from '@ngrx/store';
import { IAppConfig } from '../../interfaces/app-config';
import { IBlobSasTokens } from '../../interfaces/blob-sas';
import { IPrintPreferences } from '../../interfaces/print-preferences';
import { ITokenClaims } from '../../interfaces/token-claims';

export const updateAppVersion = createAction(
  '[Global] update app version',
  props<{ appVersion: string }>()
);

export const queryAppConfig = createAction('[Global] query app config');

export const appConfigLoaded = createAction(
  '[Global] app config loaded',
  props<{ appConfig: IAppConfig }>()
);

export const updateLeavingPageGuardEnabled = createAction(
  '[Global] update leaving page guard enabled',
  props<{ isEnabled: boolean }>()
);

export const updateTokenClaims = createAction(
  '[Global] update token data',
  props<{ tokenClaims: ITokenClaims }>()
);

export const updatePrintPreferences = createAction(
  '[Global] update print preferences',
  props<{ settings: IPrintPreferences }>()
);

export const queryBlobSasTokens = createAction(
  '[Global] query blob sas tokens'
);

export const queryBlobSasTokensSuccess = createAction(
  '[Global] query blob sas tokens Success',
  props<{ blobSasTokens: IBlobSasTokens }>()
);

export const queryBlobSasTokensError = createAction(
  '[Global] query blob sas tokens Error'
);
