import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { RxState } from '@rx-angular/state';
import { SnackBarTypes, Icons, SnackBarService } from '@my7n/ui';
import {
  Subject,
  combineLatest,
  debounceTime,
  filter,
  map,
  switchMap,
  take
} from 'rxjs';
import {
  ContentReactionTypes,
  ContentTypes,
  IContentReactionsItem,
  IContentReactionsResponse
} from '../../../interfaces/reactions';
import { ReactionsService } from '../../../services/reactions.service';
import { GlobalFacadeService } from '../../../services/facades/global-facade.service';
import { ReactionsListComponent } from './reactions-list/reactions-list.component';
import { ReactionsButtonComponent } from './reactions-button/reactions-button.component';
import { CommonModule } from '@angular/common';
import { RxLet } from '@rx-angular/template/let';

export interface IReactionsState {
  contentId: string;
  contentType: ContentTypes;
  reactions: IContentReactionsItem[];
  userId: string;
  liked: ContentReactionTypes;
}

export const initialState: IReactionsState = {
  contentId: null,
  contentType: null,
  reactions: null,
  userId: null,
  liked: null
};

@Component({
  standalone: true,
  imports: [RxLet, CommonModule, ReactionsListComponent, ReactionsButtonComponent],
  selector: 'reactions',
  templateUrl: './reactions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class ReactionsComponent implements OnInit {
  @Input() set contentId(value: string) {
    this.state.set({ contentId: value });
  }

  @Input() set contentType(value: ContentTypes) {
    this.state.set({ contentType: value });
  }

  state$ = this.state.select().pipe(debounceTime(100));
  private getReactionsTrigger = new Subject<void>();

  reactions$ = combineLatest([
    this.state.select('contentId'),
    this.state.select('contentType'),
    this.getReactionsTrigger.asObservable()
  ]).pipe(
    filter(([contentId, contentType]) => !!contentId && !!contentType),
    switchMap(([contentId, contentType]) => {
      return this.reactionsService
        .getContentReactions(contentId, contentType)
        .pipe(
          take(1),
          map((response: IContentReactionsResponse) => {
            let reactions = response.Reactions;
            return reactions;
          })
        );
    })
  );

  liked$ = combineLatest([
    this.state.select('reactions'),
    this.state.select('userId')
  ]).pipe(
    filter(([reactions, userId]) => !!reactions && !!userId),
    map(([reactions, userId]) => {
      const reaction = reactions.find((r) => r.CrmContactId === userId);
      return reaction ? reaction.ReactionType : null;
    })
  );

  constructor(
    private state: RxState<IReactionsState>,
    private reactionsService: ReactionsService,
    private snackBarService: SnackBarService,
    private globalFacadeService: GlobalFacadeService
  ) {
    this.state.set({ ...initialState });
  }

  ngOnInit(): void {
    this.initLocalState();
    this.getReactionsTrigger.next();
  }

  initLocalState() {
    this.state.connect('reactions', this.reactions$);
    this.state.connect(
      'userId',
      this.globalFacadeService.user$.pipe(map((user) => user.Id))
    );
    this.state.connect('liked', this.liked$);
  }

  onReactionClick(
    contentId: string,
    contentType: ContentTypes,
    reactionType: ContentReactionTypes
  ) {
    this.optimisticUpdate(reactionType);

    const reaction: Partial<IContentReactionsItem> = {
      ReactionType: reactionType
    };
    this.reactionsService
      .updateContentReactionsItem(contentId, contentType, reaction)
      .pipe(take(1))
      .subscribe({
        error: () => {
          this.snackBarService.open({
            message:
              'An error occurred during the reaction update. Please try again later.',
            type: SnackBarTypes.ErrorAlt,
            actionIcon: Icons.CLOSE_TINY
          });
        }
      });
  }

  private optimisticUpdate(reactionType: ContentReactionTypes) {
    const newReactions = this.state.get('reactions');
    const userId = this.state.get('userId');
    const newReaction = {
      CrmContactId: userId,
      ReactionDate: new Date(),
      ReactionType: reactionType
    };
    const currentUserReactionIdx = newReactions.findIndex(
      (item) => item.CrmContactId === userId
    );
    if (currentUserReactionIdx > -1) {
      if (reactionType !== null) {
        newReactions[currentUserReactionIdx] = newReaction;
      } else {
        newReactions.splice(currentUserReactionIdx, 1);
      }
    } else {
      if (reactionType !== null) {
        newReactions.push(newReaction);
      }
    }

    this.state.set({ reactions: newReactions, liked: reactionType });
  }
}
